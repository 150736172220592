*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: white;
}

*::-webkit-scrollbar-thumb {
    background-color: #e8e8e8;
    border: 1px solid transparent;
    border-radius: 50px;
    background-clip: content-box;
}

.chat-box::-webkit-scrollbar-track {
    background: #f2f2f2;
}
.chat-box::-webkit-scrollbar-thumb {
    background-color: #1577ba;
}
.notistack-SnackbarContainer {
    z-index: 9999 !important;
}
.message-snackbar {
    word-break: break-word;
    white-space: pre-wrap;
    align-items: end !important;
    max-width: 500px;
}
.message-snackbar > div {
    align-items: flex-end;
}

.hidden {
    height: 100%;
    min-height: 100%;
    overflow: hidden !important;
    touch-action: none;
}

@keyframes vibration {
    0% {
        transform: translateY(0);
    }
    25% {
        transform: translateY(5px);
    }
    50% {
        transform: translateY(-5px);
    }
    75% {
        transform: translateY(5px);
    }
    100% {
        transform: translateY(0);
    }
}
